<template>
  <div id="inside" v-if="showNoContractsOrAgreements">
    
    <div class="container">
      <div class="row">
        <div class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4"><i class="fa fa-2x fa-exclamation-circle pull-left mr-4 mb-2 text-primary"></i>
          <h4 class="py-4 text-primary">No momento não temos uma oferta disponível para você. </h4>
        </div>
      </div>
      <div class="row pb-5">
        <div class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4">
          <h6 class="py-2 text-muted">Conte com a gente para ter um alívio financeiro! É só selecionar o produto e acionar o atendimento que preferir:</h6>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 col-lg-3 px-4">
          <h4 class="py-4">Central de Relacionamento Cartão de Crédito </h4>
          <p><a href="tel:40043600" target="_blank">4004 3600</a> - Capitais e regiões metropolitanas.&nbsp;<br><a href="tel:08007277477" target="_blank">0800 727 7477</a> - Demais localidades.&nbsp;<br><a href="tel:551133663216" target="_blank">+55 11 3366 3216</a> - Atendimento no exterior.&nbsp;<br>De segunda a sexta-feira, das 8h às 18h30.</p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">Central de Relacionamento Cartão de Crédito Empresarial </h4>
          <p><a href="tel:1140043600" target="_blank">4004 3600</a> - Capitais e regiões metropolitanas.<br><a href="tel:08007277477" target="_blank">0800-727-7477</a> - Demais localidades.&nbsp;<br>De segunda à sexta das 7h40 às 19h00.</p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">Central de Relacionamento Empréstimo e Financiamento </h4>
          <p><a href="tel:40045215" target="_blank">4004 5215</a>&nbsp;<br>De segunda a sexta-feira, das 8h às 19h (exceto feriados).</p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">SAC</h4>
          <p><a href="tel:08007272745" target="_blank">0800 727 2745</a><br>Atendimento 24 horas.</p>
        </div>
      </div>
    </div>
      <HomeOptions />
  </div>
  <div id="inside" v-else>
    <div class="container mb-3 mt-2">
      <div class="row">
        <div v-bind:class="[largura < 500 ? 'col-12 col-lg-12 p-3' : 'col-12 col-lg-12 p-0']">
          <h5 class="text-body mb-1">Olá, {{ user.FullName }}</h5>
          <p class="text-info mb-2">CPF {{ formatDocument(user.DocNumber).split('.')[0].substring(0, 6) + '.XXX' + '.' +
            'XXX-' + formatDocument(user.DocNumber).split('-')[1] }}</p>
          <p class="text-info mb-3">Confira as opções de negociação disponíveis: </p>
        
        </div>
      </div>
    </div>

    <div class="container">
      <!--row debts-->
      
      <div v-bind:class="[largura < 500 ? '' : 'row']" style="">
        <div v-bind:class="[largura < 500 ? '' : 'd-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-12 col-sm-12 col-md-6 col-lg-6']"
          v-for="(value, key, index) in debtsCdc" :key="`${key}-${index}`">
          <div v-bind:class="[largura < 500 ? 'card mb-4 m-2' : 'card mr-3 ml-3']">
            <div class="card-header">
              <div class="row">
                <div class="col-12">
                  <h5>{{ value.product }}</h5>
                  <p>{{ formatStringToLowerCase(value.product) }}</p>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6 col-lg-4 pr-0">
                  <p class="mb-1">Valor total para negociação:</p>
                  <h6 class="text-danger">R$ {{
                    value.originalValue.toLocaleString("pt-BR", {
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })
                  }}</h6>
                </div>
                <div class="col-6 col-lg-3 pr-0">
                  <p class="mb-1">Nº do contrato:</p>
                  <h6 class="">{{ value.contract }}</h6>
                </div>
                <div class="col-6 col-lg-5 pr-0">
                  <p class="mb-1">Data da parcela mais atrasada:</p>
                  <h6 class="">{{ formatData(value.installments[0].dueDate, "YYYY-MM-DD", "L") }}</h6>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-8 col-7 pr-0">
                  <p class="mb-1 mt-2">Selecione as parcelas a negociar:</p>
                </div>
                <div class="col-lg-4 d-flex align-items-center col-5">
                  <!-- <input type="checkbox" name="select-all" id="select-all" class="float-left"> -->
                  <input type="checkbox" v-model="value.selected" :disabled="thereIsAgreementCdcClosed(value.contract)"
                    @change="selectAllParcelsContract(value)" />
                  <p class="ml-2 mr-0 mt-1 mb-1 small">Selecionar todas</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12 mb-3" style="">
                  <div class="col-12" v-if="largura > 500">
                    <div class="row" style="background-color: white; min-height: 10px; margin-bottom: -2px"></div>
                  </div>
                  <div class="col-12" v-if="largura < 500">
                    <div class="row" style="background-color: white; min-height: 15px; margin-bottom: -10px"></div>
                  </div>
                  <div class="table-responsive table-1" :style="value.installments.length > 4
                    ? 'height: 10rem;'
                    : 'height: 12rem; overflow-y: auto;'
                    ">
                    <table class="table table-hover table-striped">
                      <thead class="small" style="position: sticky; top: 0;">
                        <tr class="table-info">
                          <th scope="col" class="pr-1">Nº da parcela</th>
                          <th scope="col" class="">Vencimento</th>
                          <th scope="col" class="">Valor (R$)</th>
                          <th scope="col" class="text-center">Selecionar</th>
                        </tr>
                      </thead>
                      <tbody class="small">
                        <tr v-for="(value1, key1, index1) in value.installments" :key="`${key1}-${index1}`">
                          <td scope="row">{{ value1.installmentNumber }}</td>
                          <td>{{ formatData(value1.dueDate, "YYYY-MM-DD", "L") }}</td>
                          <td draggable="true">{{
                            value1.originalValue.toLocaleString("pt-BR", {
                              currency: "BRL",
                              minimumFractionDigits: 2,
                            })
                          }}</td>
                          <td class="text-center"><input id="checkParcels" type="checkbox" v-model="checkedParcels"
                              :value="value1" :disabled="thereIsAgreementCdcClosed(value.contract)" @change="
                                calculateSingleInstallment(value1, value)
                                " /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-lg-6">
                  <p class="mb-1">Total de parcelas selecionadas:</p>
                  <h6><b>{{ value.selected || value.parcelsIsFromCurrentContract ? checkedParcels.length : 0 }}</b></h6>
                </div>
                <div class="col-6 col-lg-6">
                  <p class="mb-1">Valor total a negociar:</p>
                  <h6><b>{{ value.selected || value.parcelsIsFromCurrentContract ? sumByContract(checkedParcels) :
                    "R$0,00" }}</b></h6>
                </div>
              </div>
              <div class="row mt-3">
                <div class="text-center col-md-12 mt-3">
                  <button class="btn mb-2 btn-primary"
                    :disabled="checkedParcels.length <= 0 && value.selected || thereIsAgreementCdcClosed(value.contract)"
                    @click="offersCdc(value)">Negociar</button>
                </div>
              </div>
              <div class="row mt-3 mb-4 px-2" v-if="thereIsAgreementCdcClosed(value.contract)">
                <div class="col-lg-1 text-center px-1 col-1"><i class="fa fa-info-circle text-danger"></i></div>
                <div class="px-1 col-lg-11 col-11">
                  <p class="small"> Existe um acordo em andamento, por isso não é possível fazer uma nova negociação.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-bind:class="[largura < 500 ? '' : 'd-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-12 col-sm-6 col-md-6 col-lg-4']"
          v-for="(value, key2, index2) in debts" :key="`${key2}-${index2}`">
          <div v-bind:class="[largura < 500 ? 'card mb-4 m-2' : 'card mr-4']" style="min-height: 265px;">
            <div class="card-header">
              <div class="row">
                <div class="col-12">
                  <h5>{{ value.product }}</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6 col-6">
                  <p class="mb-1">Valor da fatura:</p>
                  <h6 class="text-danger">R$ {{
                    value.originalValue.toLocaleString("pt-BR", {
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })
                  }}</h6>
                </div>
                <div class="col-6 col-lg-6">
                  <p class="mb-1">Venc. original:</p>
                  <h6>{{ formatData(value.dueDate, "YYYY-MM-DD", "L") }}</h6>
                </div>
              </div>
              <div class="row d-flex justify-content-center mt-3">
                <p class="mt-0 mb-4">Escolha uma opção:</p>
                <div class="text-center col-md-12">
                  <button class="btn btn-primary mr-2 mb-4" @click="verBoletos(value)">Emitir fatura</button>
                  <button class="btn mb-4 btn-outline-primary" @click="offersFatura(value)">Parcelar fatura</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-bind:class="[largura < 500 ? '' : 'd-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-12 col-sm-6 col-md-6 col-lg-4']"
          v-for="(value, key3, index3) in debtsContencioso" :key="`${key3}-${index3}`">
          <div v-bind:class="[largura < 500 ? 'card mb-4 m-2' : 'card mr-4']" style="min-height: 265px;">
            <div class="card-header">
              <div class="row">
                <div class="col-12">
                  <button class="float-right" @click="offersFaturaContencioso('descontos-disponiveis', value)">
                    <h6 class="float-right rounded small bg-success text-white py-1 px-3">Descontos <br>disponíveis</h6>
                  </button>
                  <h5>{{ value.product }}&nbsp;</h5>
                </div>
              </div>
            </div>
            <div v-bind:class="[largura < 500 ? 'card-body pt-1' : 'card-body pt-5']">
              <div class="row">
                <div class="col-lg-6 col-6">
                  <!-- quando credor renegociation será outra frase -->
                  <p class="mb-1">Valor total da fatura:</p>
                  <!-- quando credor renegociation será paymentValue -->
                  <h6 class="text-danger">R$ {{
                    value.originalValue.toLocaleString("pt-BR", {
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })
                  }}</h6>
                </div>
                <div class="col-6 col-lg-6">
                  <p class="mb-1">Venc. original:</p>
                  <h6>{{ formatData(value.dueDate, "YYYY-MM-DD", "L") }}</h6>
                </div>
              </div>
              <div
                v-bind:class="[largura < 500 ? 'row d-flex justify-content-center mt-1' : 'row d-flex justify-content-center mt-3']">
                <div v-bind:class="[largura < 500 ? 'text-center col-md-12 mt-4' : 'text-center col-md-12 mt-3']">
                  <button class="btn btn-primary mr-2 mb-4" @click="offersFaturaContencioso('negociar', value)">Negociar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--row agreements-->
      <div v-bind:class="[largura < 500 ? '' : 'row']" style="">
        <div v-bind:class="[largura < 500 ? '' : 'd-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-4']"
          v-for="(value, key4, index4) in agreements" :key="`${key4}-${index4}`">
          <div v-bind:class="[largura < 500 ? 'card mb-4 m-2' : 'card mr-4 ml-3']">
            <div class="card-header">
              <div v-bind:class="[largura < 500 ? '' : 'row']" style="">
                <div class="col-12">
                  <h6 class="float-right rounded px-2 py-1 small bg-light">Negociação realizada</h6>
                  <h6>{{ value.product.substring(0, 22) }}</h6>
                </div>
              </div>
            </div>
            <div class="card-body" :style="largura < 500 ? 'margin-top: -15px;' : ''">
              <div class="row">
                <div class="col-12 col-lg-12 pr-0">
                  <p class="mb-1">Nº do contrato:</p>
                  <h6 class="">{{ value.contract }}</h6>
                </div>
                <div class="col-lg-6 col-6 py-0">
                  <p class="mb-1">Valor negociado:</p>
                  <h6 class="text-primary">R$ {{
                    value.negociatedValue.toLocaleString("pt-BR", {
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })
                  }}</h6>
                </div>
                <div class="col-lg-6 col-6 py-0">
                  <p class="mb-1">Total de parcelas:</p>
                  <h6 class="text-primary">{{ value.installmentNumber }}x&nbsp;</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-6 py-0">
                  <p class="mb-1">Condições:</p>
                  <h6 class="text-body" v-if="value.installmentNumber > 1">Entrada R$ {{
                    parseFloat(value.firstInstallmentValue).toLocaleString("pt-BR", {
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })
                  }} + {{ value.installmentNumber - 1 }}x de R$ {{
  parseFloat(value.installmentsValue).toLocaleString("pt-BR", {
    currency: "BRL",
    minimumFractionDigits: 2,
  })
}}</h6>

                  <h6 class="text-body" v-if="value.installmentNumber <= 1">Pagamento único de R$ {{
                    parseFloat(value.firstInstallmentValue).toLocaleString("pt-BR", {
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })
                  }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-lg-6 py-0">
                  <p class="mb-1">Parcela em aberto:</p>
                  <h6 class="">{{ value.overdueInstallments }}</h6>
                </div>
                <div class="col-6 col-lg-6 py-0">
                  <p class="mb-1">Vencimento:</p>
                  <h6>{{ formatData(value.dueDate, "YYYY-MM-DD", "L") }}</h6>
                </div>
              </div>
              <div class="row mt-3">
                <div class="text-center col-md-12">
                  <button class="btn mb-2 btn-primary" @click="verBoletosSemDebts(value)">Ver boletos</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="container" v-if="loading">
      <ProgressLoading v-bind:loading="loading" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
  formatDocument,
  formatStringToLowerCase,
} from "@/utils/helpers";
import { mapState } from "vuex";
import ProgressLoading from "../../modules/auth/components/ProgressLoading.vue";
import HomeOptions from "./components/HeaderMenu.vue"
import { initializeGlobals } from '@/global';

export default {
  name: "Dashboard",
  components: {
    ProgressLoading,
    HomeOptions
  },
  data() {
    return {
      showDebtsSum: true,
      debts: [],
      debtsContencioso: [],
      debtsCdc: [],
      agreements: [],
      showNoContractsOrAgreements: false,
      largura: null,
      selectAll: false,
      checkedParcels: [],
      loading: false,
      sumTotalInstallmentsCdc: null,
      totalInstallmentsCdcWithFees: null,
      errorMessage: {},
      pageLocation: null,
      userDataStorage: {},
      url: null,
      protocol: null,
      path: null,
      host: null,
      userAdm: {},
      ipUser: null
    
    };
  },
  mounted() {
    this.$emit('updatePageView', this.pageLocation);
    localStorage.removeItem('authUser');
  },
  async created() {
    this.updateCustomData();
    this.ipUser = localStorage.getItem('ipUser');
    console.log("this.ipUser dash: " + JSON.stringify(this.ipUser));
    localStorage.setItem("currentPageName", 'dashboard');
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));

    this.url = window.location.href;
    this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.url, 'Dashboard');

    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + '//' + this.host + this.path;

    this.largura = window.screen.width;
    this.loading = true;

    try {
      const tag = localStorage.getItem('tag');
      const contracts = await this.ActionGetDebtsPortoSeguro({
        UserID: this.userAdm ? this.userAdm.UserID : null,
        UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
        tag: tag,
        OS:navigator.platform,
        IP: this.ipUser ? this.ipUser : '',
      })

      if(contracts.debts.length > 0){
        localStorage.setItem("credor", JSON.stringify(contracts.debts[0].credor));
      }

      if(contracts.agreements.length > 0){
        localStorage.setItem("credor", JSON.stringify(contracts.agreements[0].credor));
      }

      if (contracts.debts?.length) {
        contracts.debts.map((item) => {
          item.selected = false;
          item.parcelsIsFromCurrentContract = false;
        });

        this.checkContractRules(contracts)
        contracts.debts?.map(contract => {
          if ([4, 9, 33].includes(contract.credor)) {
            this.debtsContencioso.push(contract);
          } else if ([5].includes(contract.credor)) {
            this.debtsCdc.push(contract);
          } else if ([2, 7].includes(contract.credor)) {
            this.debts.push(contract);

          }
        })
      }
      if (contracts.agreements?.length > 0) {
        this.checkContractRules(contracts)
        this.agreements = contracts.agreements.length > 0 ? contracts.agreements : [];
      }

      else if (!contracts.debts?.length && !contracts.agreements?.length) {
        this.showNoContractsOrAgreements = true;
        this.errorMessage = contracts;
      }

      this.loading = true;
      this.pageLocation = window.location.href;

    } catch {
      this.loading = false;
      this.showNoContractsOrAgreements = true;
    } finally {
      this.loading = false
    }
  },

  methods: {
    formatValue,
    formatData,
    formatDocument,
    formatStringToLowerCase,

    checkContractRules(contracts) {
      contracts.debts?.map(contract => {
        if (contract.pstPaymentValue <= 0 || contract.pstOriginalValue <= 0) this.showDebtsSum = false
      })
    },


    offersFatura(agreementInfo) {
      this.eventClickToParcelarOuEmitirFaturaGTM('parcelar-fatura', agreementInfo);
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/propostas', 'propostas');
      this.updateCustomData();
      this.$router.push({
        name: "propostas",
        params: { agreementInfo }
      });
    },

    offersFaturaContencioso(event, agreementInfo) {
      this.eventClickNegociarOuDescontosDisponiveisGTM(event, agreementInfo.product);
        this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/propostasContencioso', 'propostasContencioso');
        this.updateCustomData();
        this.$router.push({
          name: "propostasContencioso",
          params: { agreementInfo }
        });
    },

    offersCdc(agreementInfo) {
      this.eventClickNegociarOuDescontosDisponiveisGTM('negociar', agreementInfo.product);
      if(this.checkedParcels.length < 1) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Aviso",
          message: "Selecione ao menos uma parcela para negociar!",
        });
        return;
      }
      agreementInfo.installments = this.checkedParcels;
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/propostasCdc', 'propostasCdc');
      this.updateCustomData();
      this.$router.push({
        name: "propostasCdc",
        params: {
          agreementInfo: agreementInfo,
          sumTotalInstallmentsCdc: this.sumTotalInstallmentsCdc,
          totalInstallmentsCdcWithFees: this.totalInstallmentsCdcWithFees,
        }
      });
    },

    ...mapActions("meusContratos", ["ActionGetDebtsPortoSeguro"]),

    async verBoletos(value) {
      this.loading = true;
      this.eventClickToParcelarOuEmitirFaturaGTM('emitir-fatura', value);
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+'/dashboard/meus-boletos', 'meus-boletos');
      this.updateCustomData();
      this.$router.push({
        name: "meusBoletos",
        params: {
          agreementInfo: value,
          fromEmitirFatura: true
        }
      });
    },

    async verBoletosSemDebts(value) {

      this.loading = true
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+'/dashboard/meus-boletos', 'meus-boletos');
      this.updateCustomData();
      this.$router.push({
        name: "meusBoletos",
        params: { agreementInfo: value, fromAgreements: true }
      });
    },

    selectAllParcelsContract(contract) {
      this.clearFlagsAnotherContracts(contract);

      if (contract.selected) {
        this.checkedParcels = [];
        this.checkedParcels = [...this.checkedParcels, ...contract.installments];
      } else {
        this.checkedParcels = this.checkedParcels.filter(
          (parcel) => !contract.installments.includes(parcel)
        );
      }

      this.checkedParcels.map((parcel) => {
        this.valueTotal += parseFloat(
          parcel.originalValue
        );
      });
    },


    controllerRuleByTypeCheckbox(contract) {
      if (this.checkedParcels.length < 1) {
        return false;
      }

      const sortedCheckedParcels = this.checkedParcels.sort((a, b) => {
        return parseInt(a.installmentNumber) - parseInt(b.installmentNumber);
      });

      // Verificar se a seleção começa pela parcela mais baixa
      if (parseInt(sortedCheckedParcels[0].installmentNumber) !== contract.installments[0].installmentNumber) {
        return false;
      }

      for (let i = 0; i < sortedCheckedParcels.length - 1; i++) {
        const currentNumber = parseInt(sortedCheckedParcels[i].installmentNumber);
        const nextNumber = parseInt(sortedCheckedParcels[i + 1].installmentNumber);

        if (nextNumber !== currentNumber + 1 && nextNumber !== currentNumber && currentNumber !== nextNumber + 1) {
          return false;
        }
      }

      return true;
    },

    async calculateSingleInstallment(installment, contract) {
      if (this.checkedParcels.length <= 0) {
        this.clearFlagsAnotherContracts();
        return;
      }
      this.thereIsThisInstallmentInTheCheckedParcels(contract);
      this.valueTotal = 0;
      var orderCorrect = await this.controllerRuleByTypeCheckbox(contract);


      if (!orderCorrect) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Aviso",
          message: "Selecione parcela(s) em sequência!",
        });

        if (this.checkedParcels.length <= 0) {
          this.clearFlagsAnotherContracts();
        }


        if (parseInt(this.checkedParcels[this.checkedParcels.length - 1].installmentNumber) === installment.installmentNumber) {
          let currentInstallment = this.checkedParcels;
          let index = currentInstallment.indexOf(installment);
          if (index > -1) {
            currentInstallment.splice(index, 1);
          }
          return;
        } else {
          this.checkedParcels.push(installment);
        }
        return;
      } else {
        this.checkedParcels.map((value) => {
          this.valueTotal += parseFloat(
            value.originalValue,
            10
          );
        });
      }
    },

    sumByContract(checkedParcels) {
      let total = 0;
      let totalWithFees = 0;
      checkedParcels.map((valueInstallment) => {
        total += parseFloat(
          valueInstallment.originalValue
        );
        totalWithFees += parseFloat(
          valueInstallment.paymentValue
        );
      });
      this.sumTotalInstallmentsCdc = total;
      this.totalInstallmentsCdcWithFees = totalWithFees;
      return total
        .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
        .replace("-", "");
    },

    thereIsAgreementCdcClosed(contractNumber) {
      var result = this.agreements.filter(function (val) {
        return (val.contract === contractNumber);
      }).length > 0;

      return result;

    },

    clearFlagsAnotherContracts(contract) {
      this.debtsCdc.map((item) => {
        if (item.contract !== contract.contract) {
          item.selected = false;
          item.parcelsIsFromCurrentContract = false;
        }
      })
    },

    thereIsThisInstallmentInTheCheckedParcels(value) {
      this.checkedParcels.map((parcels) => {
        if (value.installments.includes(parcels)) {
          value.parcelsIsFromCurrentContract = true;
        }
      });
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      // const pageName = this.$options.name;
      const userData = {
                      cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                      id: id, // Substitua pelo ID real
                      page_location: pageLocation,
                      pageName: pageName
                    };
                    localStorage.setItem("userData", JSON.stringify(userData));
    },

    eventClickNegociarOuDescontosDisponiveisGTM(option, product) {
      const nameProductFormated = formatStringToLowerCase(product);
      this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction =`click:button:${option}`;

      const eventData = {
        event: 'select_content',
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: nameProductFormated,
      };

      window.dataLayer.push({ event: 'select_content', ...eventData });
    },


    eventClickToParcelarOuEmitirFaturaGTM(action, item) {
      this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = `click:button:${action}`;

      const eventData = {
        event: 'select_content',
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: formatStringToLowerCase(item.product),
      };

      window.dataLayer.push({ ...eventData });

    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    }

    


  },

  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>
<style></style>
